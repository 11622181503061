import React, { useEffect } from "react";
import AdminHeader from "../components/AdminHeader";
import { AuthProvider } from "../auth/AuthContext";
import GeneralFilter1 from "../common/filters/GeneralFilter1";
import "../css/Page/RefundPage.css";
import apis from "../apis";
import Table from "../common/tables/Table";

const RefundPage = () => {
  const { adminData } = AuthProvider();

  const [filterAttribute, setFilterAttribute] = React.useState({
    name: "",
    roll: "",
    course: "",
    branch: "",
    batch: "",
    semester: "",
  });
  const [refundDatas, setRefundDatas] = React.useState([]);
  const [refundDataHistory, setRefundDataHistory] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [approveStatus, setApproveStatus] = React.useState("");
  const [approvedBy, setApprovedBy] = React.useState("");
  const [selectedData, setSelectedData] = React.useState([]);
  const [amount, setAmount] = React.useState("");

  const getRefundListData = async () => {
    const data = await apis.getRefundList();
    if (adminData?.role?.includes("Super Admin")) {
      const academicVerifiedData = data.filter(
        (e) =>
          e.verifiedBy === "finance" &&
          e.status !== "Unverified" &&
          e.verifiedBy !== "rejected"
      );
      // const academicVerifiedData = data;
      //add checkbox field to each data
      academicVerifiedData.map((data) => {
        return {
          ...data,
          checkbox: false,
        };
      });
      setRefundDatas(academicVerifiedData);
      setFilteredData(academicVerifiedData);
      const finalVerifiedData = data.filter(
        (e) =>
          e.verifiedBy === "registrar" ||
          e.status.includes("Rejected By Registrar")
      );
      finalVerifiedData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setRefundDataHistory(finalVerifiedData);
    } else if (adminData?.role?.includes("Finance Section")) {
      const financeVerifiedData = data.filter(
        (e) =>
          e.status === "Unverified" ||
          e.status.includes("Rejected By Registrar")
      );
      // const financeVerifiedData = data;
      financeVerifiedData.map((data) => {
        return {
          ...data,
          checkbox: false,
        };
      });
      setRefundDatas(financeVerifiedData);
      setFilteredData(financeVerifiedData);
      const academicVerifiedData = data.filter(
        (e) =>
          (e.verifiedBy === "finance" || e.verifiedBy === "registrar") &&
          e.status !== "Unverified"
      );
      academicVerifiedData.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      setRefundDataHistory(academicVerifiedData);
    }
  };

  useEffect(() => {
    if (adminData) {
      getRefundListData();
    }
  }, [adminData]);

  const requiredFilters = [
    "name",
    "roll",
    "course",
    "branch",
    "batch",
    "semester",
  ];

  useEffect(() => {
    if (refundDatas.length > 0) {
      let tempData = refundDatas;
      if (filterAttribute.name !== "") {
        tempData = tempData.filter((data) =>
          data.name.toLowerCase().includes(filterAttribute.name.toLowerCase())
        );
      }
      if (filterAttribute.roll !== "") {
        tempData = tempData.filter((data) =>
          data.roll.includes(filterAttribute.roll)
        );
      }
      if (filterAttribute.course !== "") {
        tempData = tempData.filter(
          (data) => data.course === filterAttribute.course
        );
      }
      if (filterAttribute.branch !== "") {
        tempData = tempData.filter(
          (data) => data.branch === filterAttribute.branch
        );
      }
      if (filterAttribute.batch !== "") {
        tempData = tempData.filter(
          (data) => data.batch === filterAttribute.batch
        );
      }
      if (filterAttribute.semester !== "") {
        tempData = tempData.filter(
          (data) => data.semester === filterAttribute.semester
        );
      }
      setFilteredData(tempData);
    }
  }, [filterAttribute, refundDatas]);

  const columns = [
    {
      Header: (
        <input
          className="checkbox"
          type="checkbox"
          onChange={(e) => {
            const newRefundDatas = filteredData.map((data) => {
              return {
                ...data,
                checkbox: e.target.checked,
              };
            });
            setFilteredData(newRefundDatas);
            setSelectedData(
              e.target.checked ? newRefundDatas.map((data) => data.id) : []
            );
          }}
        />
      ),
      accessor: "checkbox",
      style: { width: "50px" },
      showContextMenu: false,
    },
    {
      Header: "S.N.",
      accessor: "sno",
      style: { width: "48px", minWidth: "48px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Name",
      accessor: "name",
      style: { width: "150px", minWidth: "150px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Roll/App. ID",
      accessor: "roll",
      style: { width: "110px", minWidth: "110px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Batch",
      accessor: "batch",
      style: { width: "60px", minWidth: "60px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Current Semester",
      accessor: "semester",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Program",
      accessor: "program",
      style: { width: "85px", minWidth: "85px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Refund Date",
      accessor: "date",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Amount",
      accessor: "amount",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Transaction ID",
      accessor: "refno",
      style: { width: "100px", minWidth: "100px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Refund for Semester",
      accessor: "refund_semester",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { width: "110px", minWidth: "110px", cursor: "pointer" },
      showContextMenu: true,
    },
  ];

  const rowData = filteredData.map((data, index) => {
    return {
      checkbox: (
        <input
          className="checkbox"
          type="checkbox"
          checked={data.checkbox}
          onChange={(e) => {
            const newRefundDatas = filteredData.map((refundData) => {
              if (refundData.id === data.id) {
                return {
                  ...refundData,
                  checkbox: e.target.checked,
                };
              }
              return refundData;
            });
            setFilteredData(newRefundDatas);
            setSelectedData(
              e.target.checked
                ? [...selectedData, data.id]
                : selectedData.filter((id) => id !== data.id)
            );
          }}
        />
      ),
      sno: index + 1,
      name: data.name,
      roll: data.roll,
      batch: data.batch,
      semester: data.semester,
      program: data.course,
      date: data.date,
      amount: data.amount,
      refno: data.refno,
      refund_semester: data.refund_semester,
      status: data.status,
    };
  });

  const historyColumns = [
    {
      Header: "S.N.",
      accessor: "sno",
      style: { width: "48px", minWidth: "48px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Name",
      accessor: "name",
      style: { width: "150px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Roll/App. ID",
      accessor: "roll",
      style: { width: "110px", minWidth: "110px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Batch",
      accessor: "batch",
      style: { width: "60px", minWidth: "60px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Current Semester",
      accessor: "semester",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Program",
      accessor: "program",
      style: { width: "85px", minWidth: "85px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Refund Date",
      accessor: "date",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Amount",
      accessor: "amount",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Transaction ID",
      accessor: "refno",
      style: { width: "110px", minWidth: "110px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Refund for Semester",
      accessor: "refund_semester",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Status",
      accessor: "status",
      style: { width: "105px", minWidth: "105px", cursor: "pointer" },
      showContextMenu: true,
    },
  ];

  const historyRowData = refundDataHistory.map((data, index) => {
    return {
      sno: index + 1,
      name: data.name,
      roll: data.roll,
      batch: data.batch,
      semester: data.semester,
      program: data.course,
      date: data.date,
      amount: data.amount,
      refno: data.refno,
      refund_semester: data.refund_semester,
      status: data.status,
    };
  });

  const updateRefundStatusBy = async () => {
    // console.log(approveStatus, approvedBy, selectedData);
    if (selectedData.length === 0) {
      alert("Please select data to update status");
      return;
    }
    if (approveStatus === "") {
      alert("Please select status to update");
      return;
    }

    if (approveStatus === "Rejected By Finance") {
      selectedData.map(async (id) => {
        await apis
          .removeRefundData(id)
          .then((data) => {
            alert(data);
            getRefundListData();
          })
          .catch((err) => {
            alert(err);
          });
      });
    } else {
      const data = {
        status: approveStatus,
        verifiedBy: approvedBy,
        refundDatas: selectedData,
      };
      await apis
        .updateRefundList(data)
        .then((data) => {
          alert(data);
          getRefundListData();
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const updateRefundAmount = async () => {
    if (selectedData.length === 0) {
      alert("Please select data to update amount");
      return;
    }
    if (amount === "") {
      alert("Please enter amount to update");
      return;
    }

    selectedData.map(async (id) => {
      await apis
        .updateRefundAmount(id, amount)
        .then((data) => {
          alert(data);
          getRefundListData();
        })
        .catch((err) => {
          alert(err);
        });
    });
  };

  return (
    <div>
      <AdminHeader />
      {adminData?.role?.includes("Finance Section") ||
        (adminData?.role?.includes("Super Admin") && (
          <GeneralFilter1
            filterHeading="Filter Data By"
            filters={requiredFilters}
            setFilterAttribute={setFilterAttribute}
            filterAttribute={filterAttribute}
            containerClass="newAdmissionFilterContainer"
            titleClass="newAdmissionFilterTitle"
          />
        ))}
      {(adminData?.role?.includes("Finance Section") ||
        adminData?.role?.includes("Super Admin")) && (
          <div className="upper-section-refund">
            <div className="left-section-refund">
              <Table columns={columns} data={rowData} />
            </div>
            <div className="right-section-refund">
              <div className="">
                <label style={{
                  fontWeight: "600",
                }}>Update Selected Refund Amount</label>
                <input
                  type="text"
                  value={amount}
                  className="updateRefundAmount"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^[0-9]*$/.test(value)) {
                      setAmount(value);
                    }
                  }}
                />
                <button
                  className="primary updateRefundButton"
                  onClick={() => updateRefundAmount()}
                >
                  Update
                </button>
              </div>
              <div className="" style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}>
                <label style={{
                  fontWeight: "600",
                }}>Update Selected Refund Status</label>
                <select
                  onChange={(e) => {
                    setApproveStatus(e.target.value);
                    setApprovedBy(
                      e.target.value.includes("Rejected")
                        ? "rejected"
                        : e.target.value.includes("Registrar")
                        ? "registrar"
                        : "finance"
                    );
                  }}
                >
                  {adminData?.role?.includes("Super Admin") ? (
                    <>
                      <option value="">Choose Status</option>
                      <option value="Approved By Registrar (Step-2/2)">
                        Approve
                      </option>
                      <option value="Rejected By Registrar">Reject</option>
                    </>
                  ) : (
                    <>
                      <option value="">Choose Status</option>
                      <option value="Approved By Finance (Step-1/2)">
                        Approve
                      </option>
                      <option value="Rejected By Finance">Reject</option>
                    </>
                  )}
                </select>
                <button className="primary updateRefundButton" onClick={() => updateRefundStatusBy()}>
                  Update
                </button>
              </div>
            </div>
          </div>
        )}
      <div className="lower-section-refund">
        <Table columns={historyColumns} data={historyRowData} />
      </div>
    </div>
  );
};

export default RefundPage;
