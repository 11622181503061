import React, { useEffect, useState } from "react";
import { AuthProvider } from "../auth/AuthContext";
import "../css/Page/StudentBankDetailAdmin.css";
import AdminHeader from "../components/AdminHeader";
import GeneralFilter1 from "../common/filters/GeneralFilter1";
import apis from "../apis";
import { Link } from "react-router-dom";
import Table from "../common/tables/Table";

const arrayBufferToURL = (buffer, type) => {
  var data = new Uint8Array(buffer);
  var blob = new Blob([data], { type: type });
  var url = URL.createObjectURL(blob);
  return url;
};

const arrayBufferToBase64 = (buffer) => {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
};

const StudentBankDetailAdmin = () => {
  const { adminData } = AuthProvider();

  const [filterAttribute, setFilterAttribute] = useState({});
  const [studentData, setStudentData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getStudentBankData = async () => {
    setLoading(true);
    try {
      await apis
        .getAllStudentBankDetails()
        .then((data) => {
          setStudentData(data);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err.message);
        });
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getStudentBankData();
  }, []);

  const [filteredData, setFilteredData] = useState([]);

  React.useEffect(() => {
    if (studentData.length > 0) {
      let tempData = studentData;
      for (let key in filterAttribute) {
        if (filterAttribute[key] !== "" && key !== "status1") {
          tempData = tempData.filter(
            (data) => data[key] === filterAttribute[key]
          );
        }
      }

      if (filterAttribute["status1"] === "Available") {
        tempData = tempData.filter(
          (data) =>
            data["bankName"] !== null ||
            data["accountNumber"] !== null ||
            data["ifscCode"] !== null
        );
      } else if (filterAttribute["status1"] === "Not-Available") {
        tempData = tempData.filter(
          (data) =>
            data["bankName"] === null &&
            data["accountNumber"] === null &&
            data["ifscCode"] === null
        );
      }
      console.log(tempData);
      setFilteredData(tempData);
    }
  }, [filterAttribute, studentData]);

  // Specify the filters required for this page
  const requiredFilters = [
    "roll",
    "course",
    "batch",
    "branch",
    "semester",
    "status1",
  ];

  const columns = [
    {
      Header: "S.No.",
      accessor: "sno",
    },
    {
      Header: "Roll No.",
      accessor: "roll",
      style: { width: "160px", minWidth: "160px" },
    },
    {
      Header: "Account Number",
      accessor: "accountNumber",
      style: { width: "300px", minWidth: "200px" },
    },
    {
      Header: "Bank Name",
      accessor: "bankName",
      style: { width: "200px", minWidth: "150px" },
    },
    {
      Header: "IFSC Code",
      accessor: "ifscCode",
      style: { width: "150px", minWidth: "100px" },
    },
    {
      Header: "Passbook",
      accessor: "passbook",
      style: { width: "100%", minWidth: "100px" },
    },
  ];
  const [openPassbookLoader, setOpenPassbookLoader] = useState(false);
  const [currRoll, setCurrRoll] = useState(null);

  const handlePassbook = (roll) => {
    setCurrRoll(roll);
    setOpenPassbookLoader(true);
  };

  const rowData =
    filteredData &&
    filteredData.map((data, index) => {
      return {
        sno: index + 1,
        roll: data.roll,
        accountNumber: data.accountNumber ? (
          data.accountNumber
        ) : (
          <span style={{ color: "#c4c4c4" }}>N/A</span>
        ),
        bankName: data.bankName ? (
          data.bankName
        ) : (
          <span style={{ color: "#c4c4c4" }}>N/A</span>
        ),
        ifscCode: data.ifscCode ? (
          data.ifscCode
        ) : (
          <span style={{ color: "#c4c4c4" }}>N/A</span>
        ),
        passbook: (
          <span
            onClick={() => handlePassbook(data.roll)}
            style={{ textDecoration: "underline", color: "#f3b300" }}
          >{`${data.name
            .replace(/\s+/g, "")
            .toLowerCase()}_passbook.pdf`}</span>
        ),
      };
    });

  return (
    <div>
      {openPassbookLoader && (
        <OpenPassbookLoader
          roll={currRoll}
          open={openPassbookLoader}
          setOpen={setOpenPassbookLoader}
        />
      )}
      <AdminHeader />
      <GeneralFilter1
        filterHeading="Filter Bank Details"
        filters={requiredFilters}
        setFilterAttribute={setFilterAttribute}
        filterAttribute={filterAttribute}
      />
      <div className="tableContainerBank">
        <Table
          columns={columns}
          data={rowData}
          loading={loading}
          className="bankDetailTable"
        />
      </div>
      <DownloadBankDetailsCsv bankDetails={filteredData} />
    </div>
  );
};

const OpenPassbookLoader = ({ roll, open, setOpen }) => {
  const [passData, setPassData] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  useEffect(() => {
    if (open && roll) {
      const fetchPassbook = async () => {
        await apis
          .getPassBookByRoll(roll)
          .then((data) => {
            setPassData(data?.passbook);
            setFilePreview(
              `data:application/pdf;base64,${arrayBufferToBase64(
                data?.passbook.data
              )}`
            );
          })
          .catch((err) => {
            console.log(err.message);
            setOpen(false);
          });
      };
      fetchPassbook();
    } else {
      setOpen(false);
    }
  }, [open, roll, setOpen]);

  return (
    <div
      className="passbookLoader"
      style={{
        display: open ? "flex" : "none",
        position: "fixed",
        zIndex: "1000",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(5px)",
        width: "100vw",
        height: "100vh",
      }}
    >
      {passData ? (
        <div style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
          width: "70%",
          height: "70vh",
          backgroundColor: "white",
          borderRadius: "10px",
          padding: "20px",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
          position: "relative",
        }}>
          <h3
            style={{
              margin: "0px auto"
            }}
          >Passbook Preview</h3>
          <button onClick={()=>setOpen(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
              color: "red",
              fontSize: "20px",
            }}
            >
            <i class="fa-solid fa-xmark"></i>
          </button>
          {filePreview && (
            <div
            style={{
              width: "100%", 
              height: "100%",
              overflow: "hidden", 
              position: "relative",
            }}
          >

            <iframe
            src={filePreview}
            width="100%"
            height="100%"
            title="Passbook Preview"
            style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              boxShadow: 'inset 0 4px 8px rgba(0, 0, 0, 0.1)',
              overflow: 'auto',
              scrollbarWidth: 'thin',
            }}
          />
          </div>
          )}
        <Link
          to={arrayBufferToURL(passData.data, "application/pdf")}
          style={{ marginTop: "5px", textDecoration: "underline" }}
          target="_blank"
        >
          <p onClick={() => setOpen(false)}>If preview is not visible then click here to open the file in new tab</p>
        </Link>
        </div>
      ) : (
        <h1>Opening...</h1>
      )}
    </div>
  );
};

const DownloadBankDetailsCsv = ({ bankDetails }) => {
  async function downloadCsv() {
    const headers = [
      {
        label: "Roll No.",
        value: "roll",
      },
      {
        label: "Email",
        value: "email",
      },
      {
        label: "Account Number",
        value: "accountNumber",
      },
      {
        label: "Bank Name",
        value: "bankName",
      },
      {
        label: "IFSC Code",
        value: "ifscCode",
      },
    ];
    await apis
      .downloadCsv(bankDetails, headers, "bankDetails.csv")
      .then((data) => {
        let url = window.URL.createObjectURL(data);
        window.open(url, +"_blank");
        alert("Downloaded");
      })
      .catch((err) => {
        alert(err.message);
      });
  }
  return (
    <div className="result-footer">
      <div className="result-footer-title">
        <h2>Export Bank details</h2>
        <p>(Get an offline .csv file of above filtered data)</p>
      </div>
      <div className="result-footer-button">
        <button onClick={() => downloadCsv()}>
          Start exporting now as .csv file{" "}
          <i className="fa-solid fa-circle-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default StudentBankDetailAdmin;
