import * as React from "react";
import { useForm } from "react-hook-form";
import apis from "../apis";
import ButtonLoader from "./ButtonLoader";

const EditAdminPopUp = ({ open, onClose, role, manageFaculty, adminData }) => {
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [facultyData, setFacultyData] = React.useState();
  const [updateAdmin, setUpdateAdmin] = React.useState({
    program: [],
    batch: [],
    branch: [],
    role: [],
    wardenHostel: "",
  });

  const [batchList, setBatchList] = React.useState();

  React.useEffect(() => {
    const fetchBatchList = async () => {
      await apis
        .getBatchFilterListComplete()
        .then((data) => {
          setBatchList(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchBatchList();
  }, []);

  const { register, handleSubmit } = useForm();
  const onSubmit = async (data) => {
    const { program, batch, branch, role, wardenHostel } = data;
    let programArray = [facultyData?.program];
    let batchArray = [facultyData?.batch];
    let branchArray = [facultyData?.branch];
    let roleArray = [adminData?.role];
    if (program) {
      programArray = [...program];
    }
    if (batch) {
      batchArray = [...batch];
    }
    if (branch) {
      branchArray = [...branch];
    }
    if (role) {
      roleArray = [...role];
    }

    await apis
      .updateAdmin({
        id: adminData?.id,
        programArray,
        batchArray,
        branchArray,
        roleArray,
        wardenHostel,
      })
      .then((res) => {
        window.location.reload();
        alert("Admin Updated");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  React.useEffect(() => {
    const fetchFacultyData = async () => {
      await apis
        .getFaculty(adminData?.id)
        .then((data) => {
          setFacultyData(data);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchFacultyData();
  }, [adminData?.id]);

  React.useEffect(() => {
    setUpdateAdmin({
      program: facultyData?.program,
      batch: facultyData?.batch,
      branch: facultyData?.branch,
      role: adminData?.role,
      wardenHostel: adminData?.wardenHostel,
    });
  }, [facultyData, adminData]);

  if (!open) return null;
  return (
    <>
      <div className="gateway-popup">
        <div className="gateway-popup-title">
          <h2>Edit Admin: ({adminData?.name})</h2>
          <button onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="gateway-popup-content DocAddPopup-content MA multiselectPopUp">
          <form onSubmit={handleSubmit(onSubmit)}>
            {manageFaculty && (
              <div className="form-group multiple-select">
                <label htmlFor="program">Select Program</label>
                <select
                  name="adminType"
                  id="adminType"
                  value={updateAdmin?.program}
                  multiple
                  {...register("program")}
                  onChange={(e) => {
                    setUpdateAdmin({
                      ...updateAdmin,
                      program: Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      ),
                    });
                  }}
                >
                  <option value="B.TECH.">B.Tech</option>
                  <option value="M.TECH.">M.Tech</option>
                  <option value="Ph.D.">PhD</option>
                </select>
                <label htmlFor="batch">Select Batch</label>
                <select
                  name="batch"
                  id="batch"
                  multiple
                  value={updateAdmin?.batch}
                  {...register("batch")}
                  onChange={(e) => {
                    setUpdateAdmin({
                      ...updateAdmin,
                      batch: Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      ),
                    });
                  }}
                >
                  <option disabled value="">
                    Select Batch
                  </option>
                  <option disabled value="">
                    B.Tech.
                  </option>
                  {batchList["B.TECH."]?.map((batch) => (
                    <option key={batch} value={batch}>
                      {batch}
                    </option>
                  ))}
                  <option disabled value="">
                    M.Tech.
                  </option>
                  {batchList["M.TECH."]?.map((batch) => (
                    <option key={batch} value={batch}>
                      {batch}
                    </option>
                  ))}
                  <option disabled value="">
                    Ph.D.
                  </option>
                  {batchList["Ph.D."]?.map((batch) => (
                    <option key={batch} value={batch}>
                      {batch}
                    </option>
                  ))}
                </select>
                <label htmlFor="branch">Select Branch</label>
                <select
                  name="branch"
                  id="branch"
                  multiple
                  value={updateAdmin?.branch}
                  {...register("branch")}
                  onChange={(e) => {
                    setUpdateAdmin({
                      ...updateAdmin,
                      branch: Array.from(
                        e.target.selectedOptions,
                        (option) => option.value
                      ),
                    });
                  }}
                >
                  <option value="CSE">CSE</option>
                  <option value="ECE">ECE</option>
                  <option value="MAE">MAE</option>
                  <option value="MNC">MNC</option>
                  <option disabled>M.Tech</option>
                  <option value="AI & DS">AI & DS</option>
                  <option value="SPML">SPML</option>
                  <option value="VLSI & ES">VLSI & ES</option>
                  <option value="Micro & Comm.">Micro & Comm.</option>
                  <option value="EV">EV</option>
                </select>
              </div>
            )}
            <div className="form-group multiple-select">
              <label htmlFor="adminType">Select Admin Type/Role</label>
              <select
                name="adminType"
                id="adminType"
                required
                value={updateAdmin?.role}
                multiple
                {...register("role")}
                onChange={(e) => {
                  setUpdateAdmin({
                    ...updateAdmin,
                    role: Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    ),
                  });
                }}
              >
                {!manageFaculty && (
                  <option value="Super Admin">Super Admin</option>
                )}
                {!manageFaculty && (
                  <option value="Academic Admin">Academic Admin</option>
                )}
                {!manageFaculty && <option value="DOSA">DOSA</option>}
                {!manageFaculty && (
                  <option value="Academic Office">Academic Office</option>
                )}
                {!manageFaculty && <option value="DOAA">DOAA</option>}
                {!manageFaculty && (
                  <option value="Finance Section">Finance Section</option>
                )}
                {!manageFaculty && (
                  <option value="Document Verifier">Document Verifier</option>
                )}
                {!manageFaculty && (
                  <option value="Student's Application Verifier">Student's Application Verifier</option>
                )}
                {!manageFaculty && (
                  <option value="JOSAA-CSAB-CCMT Seat Allotment">
                    JOSAA-CSAB-CCMT Seat Allotment
                  </option>
                )}
                {!manageFaculty && (
                  <option value="Complaint-Electrical">
                    Complaint-Electrical
                  </option>
                )}
                {!manageFaculty && (
                  <option value="Complaint-Internet">Complaint-Internet</option>
                )}
                {!manageFaculty && (
                  <option value="Complaint-Civil">Complaint-Civil</option>
                )}
                {!manageFaculty && (
                  <option value="Complaint-Civil">Complaint-Others</option>
                )}
                {!manageFaculty && <option value="Faculty">Faculty</option>}
                {!manageFaculty && (
                  <option value="Faculty Advisor">Faculty Advisor</option>
                )}
                {manageFaculty && <option value="Faculty">Faculty</option>}
                {manageFaculty && <option value="HOD">HOD</option>}
                {manageFaculty && (
                  <option value="Faculty Advisor">Faculty Advisor</option>
                )}
                {!manageFaculty && <option value="HAB">HAB</option>}
                {!manageFaculty && (
                  <option value="Warden">Hostel Warden</option>
                )}
              </select>
            </div>
            {!manageFaculty && (
              <div className="form-group">
                <label htmlFor="adminType">
                  Select Hostel (If Role is Warden)
                </label>
                <select
                  name="adminType"
                  id="adminType"
                  value={updateAdmin?.wardenHostel}
                  required
                  {...register("wardenHostel")}
                  onChange={(e) => {
                    setUpdateAdmin({
                      ...updateAdmin,
                      wardenHostel: e.target.value,
                    });
                  }}
                >
                  {!manageFaculty && <option value="H1">H1</option>}
                  {!manageFaculty && <option value="H9">H9</option>}
                  {!manageFaculty && <option value="H7">H7</option>}
                  {!manageFaculty && <option value="NBH">NBH</option>}
                  {!manageFaculty && <option value="GH">GH</option>}
                  {!manageFaculty && <option value="KBH">KBH</option>}
                  {!manageFaculty && <option value="KGH">KGH</option>}
                </select>
              </div>
            )}
            {btnLoading ? (
              <button className="primary" type="submit">
                <ButtonLoader />
              </button>
            ) : (
              <button
                className="primary"
                type="submit"
                onClick={() => setBtnLoading(true)}
              >
                Save and Update
              </button>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default EditAdminPopUp;
