import React, { useEffect } from "react";
import "../css/Component/BonafideRequestsPopUp.css";
import BonaRequestElement from "./BonaRequestElement";
import { DataGrid } from "@mui/x-data-grid";
import apis from "../apis";
import FilterComponent from "./FilterComponent";
import BonafiedFilters from "./BonafiedFilters";

const BonafideRequestsPopUp = ({
  open,
  onClose,
  applications,
  setApplications,
  setId,
  setAgain,
  setType,
  setTempType,
  setBonaRequestsPopup,
}) => {
  const [minimized, setMinimized] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterApplications, setFilterApplications] = React.useState([]);

  const [filterAttributes, setFilterAttributes] = React.useState({
    startDate: "",
    endDate: "",
    type: "",
    roll: "",
    sortBy: "Oldest",
  });

  const blobUrl = (value) => {
    if (value?.data === null) return null;
    const url = URL.createObjectURL(
      new Blob([new Uint8Array(value?.data)], {
        type: "application/pdf",
      })
    );
  };

  const handleFilterChange = (e) => {
    setFilterAttributes({
      ...filterAttributes,
      [e.target.name]: e.target.value,
    });
  };

  const resetFilters = () => {
    console.log(filterAttributes);
    setFilterAttributes({
      startDate: "",
      endDate: "",
      type: "",
      roll: "",
      sortBy: "Oldest",
    });
  };

  const mardAsDone = async (id, purpose) => {
    // console.log(purpose);
    await apis
      .generateBonafide(id, purpose)
      .then((res) => {
        setApplications((prev) =>
          prev.filter((application) => application.id !== id)
        );
        alert("This request has been marked as done");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  useEffect(() => {
    let allApplications = applications;
    if (filterAttributes.startDate) {
      allApplications = allApplications?.filter(
        (apply) =>
          new Date(apply.createdAt?.split("T")[0]) >= new Date(filterAttributes.startDate)
      );
    }
    if (filterAttributes.endDate) {
      allApplications = allApplications?.filter(
        (apply) =>
          new Date(apply.createdAt?.split("T")[0]) <= new Date(filterAttributes.endDate)
      );
    }
    if (filterAttributes.type) {
      allApplications = allApplications?.filter(
        (apply) => apply.applicationType === filterAttributes.type
      );
    }
    if (filterAttributes.roll) {
      allApplications = allApplications?.filter(
      (apply) => apply.roll.startsWith(filterAttributes.roll)
      );
    }
    if (filterAttributes.sortBy === "Oldest") {
      allApplications = allApplications?.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    } else if (filterAttributes.sortBy === "Most Recent") {
      allApplications = allApplications?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    }

    setFilterApplications(allApplications);
  }, [filterAttributes, applications]);

  const columns = [
    {
      field: "date",
      headerName: "Apply Date",
      sortable: true,
      minWidth: 130,
      flex: 0.3,
    },
    {
      field: "roll_no",
      headerName: "Roll No.",
      minWidth: 130,
      flex: 0.3,
    },
    {
      field: "type",
      headerName: "Aplication Type",
      minWidth: 100,
      flex: 0.3,
    },

    {
      field: "purpose",
      headerName: "Purpose",
      sortable: false,
      minWidth: 270,
      flex: 0.5,
    },
    {
      field: "format",
      headerName: "Format",
      sortable: false,
      minWidth: 130,
      flex: 0.2,
    },
    {
      field: "default",
      headerName: "Generate Default",
      sortable: false,
      minWidth: 150,
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => {
        return (
          <>
            <button
              className="primary generate-primary"
              type="submit"
              onClick={() => {
                setMinimized(true);
                setId(params?.id);
                setType(params?.applicationType);
                setTempType(params?.applicationType);
                setAgain("n");
                // window.location.pathname = `/student/admin/documents-bonafide/${params?.id}/n`;
              }}
            >
              Generate
            </button>
          </>
        );
      },
    },
    {
      field: "manual",
      headerName: "if done manually",
      sortable: false,
      minWidth: 150,
      flex: 0.3,
      renderCell: (params) => {
        const currPurpose = applications.filter(
          (application) => application?.id === params?.id
        )[0]?.purpose;
        return (
          <>
            <button
              className="secondary mark-as-done"
              type="submit"
              onClick={() => mardAsDone(params?.id, currPurpose)}
            >
              Mark as done
            </button>
          </>
        );
      },
    },
  ];

  const rows = [];

  filterApplications?.map((application) => {
    rows.push({
      id: application.id,
      date: application.date,
      roll_no: application.roll,
      type:
        application.applicationType === null
          ? "Bonafide"
          : application.applicationType,
      purpose: application.purpose,
      format:
        application.otherFormat !== null ? (
          <a href={blobUrl(application?.otherFormat)}>Requested Format</a>
        ) : (
          "N/A"
        ),
      default: application.done,
      manual: application.done,
    });
  });

  if (!open) return null;

  return (
    <>
      <div
        className={`bonafied-gateway-popup ${
          minimized && "bonafied-gateway-popup-minimized"
        }`}
      >
        {minimized && (
          <div
            className="overlay"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.7)",
              zIndex: 1000,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            onClick={() => setMinimized(false)}
          ><i class="fa-solid fa-expand" style={{fontSize: "250px"}}></i></div>
        )}
        {
          openFilter && (
            <div className="filter-popup">
              <BonafiedFilters
              filterAttributes={filterAttributes}
              handleFilterChange={handleFilterChange}
              resetFilters={resetFilters}
              setOpenFilter={setOpenFilter}
            />
            </div>
          )
        }
        <div className="gateway-popup-title">
          <h2>
            Requests for Bonafide: <span>{applications?.length}</span>
          </h2>
          <button onClick={() => setMinimized(true)}> <i class="fa-solid fa-down-left-and-up-right-to-center"></i> </button>
          <button onClick={() => setOpenFilter(true)}> <i class="fa-solid fa-filter"></i> </button>
          <button onClick={onClose}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoPageSize
          className="productListTable"
        />
        {/* <div className="gateway-popup-content BonafideRequests-content">
          <div className="result-items history bonafide-history-contents">
            <div className="result-heading">
              <div className="result-heading-item">
                <h4>Roll Number</h4>
              </div>
              <div className="result-heading-item">
                <h4>Application Type</h4>
              </div>
              <div className="result-heading-item">
                <h4>Purpose</h4>
              </div>
              <div className="result-heading-item">
                <h4>Format</h4>
              </div>
              <div className="result-heading-item">
                <h4>Generate Default</h4>
              </div>
              <div className="result-heading-item">
                <h4>if done manually</h4>
              </div>
            </div>
            <div className="result-contents">
              {applications.map((application) => (
                <BonaRequestElement
                  key={application.id}
                  application={application}
                  setBonafideRequestsPopup={setBonaRequestsPopup}
                />
              ))}
            </div>
            <div className="result-content">
              <h4>No more requests for now :)</h4>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default BonafideRequestsPopUp;
