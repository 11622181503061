import React, { createContext, useContext, useEffect, useState } from "react";
import AdminHeader from "../components/AdminHeader";
import "../css/Page/ManageAdmins.css";
import AdminAccountElement from "../components/AdminAccountElement";
import AdminUpdatePopUp from "../components/AdminUpdatePopUp";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";
import EditAdminPopUp from "../components/EditAdminPopUp";
import Table from "../common/tables/Table";

const AdminContext = createContext();

const StudentList = ({ student, index }) => {
  const { assignFaculty, setAssignFaculty } = useContext(AdminContext);
  const [selected, setSelected] = useState(false);
  const updateData = () => {
    if (!selected) {
      setAssignFaculty((e) => {
        return [...e, student?.studentId];
      });
    } else {
      let index = assignFaculty?.findIndex((e) => e === student?.studentId);
      if (index > -1) {
        setAssignFaculty((e) => e.splice(index, 1));
      }
    }
  };

  return (
    <div className="request-list-element">
      <div className="request-list-element-item checkbox-container">
        <input
          className="checkbox"
          type="checkbox"
          value={selected}
          onChange={(e) => {
            setSelected(e.target.checked);
            updateData();
          }}
        />
        <h4 style={{ marginRight: "10px" }}>{index + 1}. </h4>
        <h4>{student?.name}</h4>
      </div>
      <div className="request-list-element-item">
        <h4>{student?.studentId}</h4>
      </div>
      <div className="request-list-element-item">
        <h4>{student?.program}</h4>
      </div>
      <div className="request-list-element-item">
        <h4>{student?.batch}</h4>
      </div>
      <div className="request-list-element-item">
        <h4>{student?.branch}</h4>
      </div>
      <div
        className="request-list-element-item"
        style={({ width: "20%" }, { marginRight: "5px" })}
      >
        <h4>{student?.faculty}</h4> {/* or Approved */}
      </div>
    </div>
  );
};

const StudentRowListCheckBox = ({ student }) => {
  const { assignFaculty, setAssignFaculty } = useContext(AdminContext);
  const [selected, setSelected] = useState(false);
  const updateData = () => {
    if (!selected) {
      setAssignFaculty((e) => {
        return [...e, student?.studentId];
      });
    } else {
      let index = assignFaculty?.findIndex((e) => e === student?.studentId);
      if (index > -1) {
        setAssignFaculty((e) => e.splice(index, 1));
      }
    }
  };

  return (
    <div className="request-list-element-item checkbox-container">
      <input
        className="checkbox"
        type="checkbox"
        value={selected}
        onChange={(e) => {
          setSelected(e.target.checked);
          updateData();
        }}
      />
    </div>
  );
};

const ManageAdmins = () => {
  const [openAdminUpdatePopUp, setOpenAdminUpdatePopUp] = useState({
    open: false,
    manageFaculty: false,
  });
  const [adminData1, setAdminData1] = useState();
  const [phdStudents, setPhdStudents] = useState();
  const { adminData } = AuthProvider();
  const [faculties, setFaculties] = useState();
  const [assignFaculty, setAssignFaculty] = useState([]);
  const [faculty, setFaculty] = useState();

  const fetchAllPhdStudents = React.useCallback(async () => {
    await apis
      .getAllPhdStudents()
      .then((res) => {
        setPhdStudents(res);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  useEffect(() => {
    const fetchAllAdmins = async () => {
      await apis
        .allAdmins()
        .then((res) => {
          console.log(res);
          setAdminData1(res);
        })
        .catch((err) => {
          alert(err.message);
        });
    };

    const fetchAllFaculties = async () => {
      await apis
        .getFaculties()
        .then((data) => {
          setFaculties(data);
        })
        .catch((err) => {
          alert(err.message);
        });
    };
    fetchAllFaculties();
    fetchAllPhdStudents();
    fetchAllAdmins();
  }, [fetchAllPhdStudents]);

  const assignFacultyFunc = async () => {
    await apis
      .assignFaculties({ rolls: assignFaculty, facultyName: faculty })
      .then((data) => {
        alert("Faculties Assigned!!");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const [search, setSearch] = React.useState({
    batch: "",
    course: "",
    branch: "",
  });

  const resetFilter = () => {
    setSearch({
      batch: "",
      course: "",
      branch: "",
    });
    fetchAllPhdStudents();
  };

  const filterStudents = () => {
    let filteredData = phdStudents;

    if (search.course !== "") {
      filteredData = filteredData.filter((e) => e.program === search.course);
    }

    if (search.batch !== "") {
      filteredData = filteredData.filter((e) => e.batch === search.batch);
    }

    if (search.branch !== "") {
      filteredData = filteredData.filter((e) => e.branch === search.branch);
    }

    setPhdStudents(filteredData);
  };

  const [batchList, setBatchList] = React.useState();

  React.useEffect(() => {
    const fetchBatchList = async () => {
      await apis
        .getBatchFilterList()
        .then((data) => {
          setBatchList(data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    fetchBatchList();
  }, []);

  const [openEditAdminPopUp, setOpenEditAdminPopUp] = React.useState({
    open: false,
    manageFaculty: false,
  });

  const [specificAdminData, setSpecificAdminData] = useState();

  const deleteAdmin = async (id) => {
    await apis
      .deleteAdmin({ id: id })
      .then((res) => {
        window.location.reload();
        alert("Admin Deleted");
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  const adminColumn = [
    {
      Header: "Name",
      accessor: "name",
      style: { width: "200px", minWidth: "200px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Email",
      accessor: "email",
      style: { width: "220px", minWidth: "220px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Admin Type/Role",
      accessor: "role",
      style: { width: "200px", minWidth: "200px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Edit Admin",
      accessor: "edit",
      style: { width: "50%", minWidth: "50%", cursor: "pointer" },
      showContextMenu: false,
    },
    {
      Header: "Delete User",
      accessor: "delete",
      style: { width: "50%", minWidth: "50%", cursor: "pointer" },
      showContextMenu: false,
    },
  ];

  const adminRowData = adminData1
    ?.filter((e) => {
      return (
        !e?.role?.find((e1) => {
          return e1 === "Faculty Advisor";
        }) ||
        !e?.role?.find((e1) => {
          return e1 === "Faculty";
        }) ||
        !e?.role?.find((e1) => {
          return e1 === "HOD";
        })
      );
    })
    .map((admin) => {
      return {
        name: admin.name,
        email: admin.email,
        role: admin.role.join(", "),
        edit: (
          <button
            className="primary"
            onClick={() => {
              setOpenEditAdminPopUp({
                open: true,
                manageFaculty: false,
              });
              setSpecificAdminData(admin);
            }}
            style={{ borderRadius: "5px", padding: "8px 30px" }}
          >
            Edit
          </button>
        ),
        delete: (
          <button
            className="secondary"
            onClick={() => deleteAdmin(admin.id)}
            style={{ borderRadius: "5px", padding: "8px 30px" }}
          >
            Delete
          </button>
        ),
      };
    });

  const facultyColumn = [
    {
      Header: "Name",
      accessor: "name",
      style: { width: "200px", minWidth: "200px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Email",
      accessor: "email",
      style: { width: "220px", minWidth: "220px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Batch",
      accessor: "batch",
      style: { width: "100px", minWidth: "100px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Branch",
      accessor: "branch",
      style: { width: "120px", minWidth: "120px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Program",
      accessor: "program",
      style: { width: "130px", minWidth: "130px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Faculty Role",
      accessor: "role",
      style: { width: "160px", minWidth: "160px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Edit Faculty",
      accessor: "edit",
      style: { width: "50%", minWidth: "50%", cursor: "pointer" },
      showContextMenu: false,
    },
    {
      Header: "Delete User",
      accessor: "delete",
      style: { width: "50%", minWidth: "50%", cursor: "pointer" },
      showContextMenu: false,
    },
  ];

  const facultyRowData = adminData1
    ?.filter((e) => {
      return (
        e?.role?.find((e1) => {
          return e1 === "Faculty Advisor";
        }) ||
        e?.role?.find((e1) => {
          return e1 === "Faculty";
        }) ||
        e?.role?.find((e1) => {
          return e1 === "HOD";
        })
      );
    })
    .map((admin) => {
      return {
        name: admin.name,
        email: admin.email,
        batch: faculties
          ?.find((e) => e.adminId === admin.id)
          ?.batch?.join(", "),
        branch: faculties
          ?.find((e) => e.adminId === admin.id)
          ?.branch?.join(", "),
        program: faculties
          ?.find((e) => e.adminId === admin.id)
          ?.program?.join(", "),
        role: admin?.role?.join(", "),
        edit: (
          <button
            className="primary"
            onClick={() => {
              setOpenEditAdminPopUp({
                open: true,
                manageFaculty: true,
              });
              setSpecificAdminData(admin);
            }}
            style={{ borderRadius: "5px", padding: "8px 30px" }}
          >
            Edit
          </button>
        ),
        delete: (
          <button
            className="secondary"
            onClick={() => deleteAdmin(admin.id)}
            style={{ borderRadius: "5px", padding: "8px 30px" }}
          >
            Delete
          </button>
        ),
      };
    });

  const assignFacultyColumn = [
    {
      Header: "Select",
      accessor: "select",
      style: { width: "20px", minWidth: "20px" },
      showContextMenu: false,
    },
    {
      Header: "S.No.",
      accessor: "sno",
      style: { width: "30px", minWidth: "30px" },
      showContextMenu: false,
    },
    {
      Header: "Student Name",
      accessor: "name",
      style: { width: "190px", minWidth: "190px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Roll No.",
      accessor: "roll",
      style: { width: "70px", minWidth: "70px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Program",
      accessor: "program",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Batch",
      accessor: "batch",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Branch",
      accessor: "branch",
      style: { width: "80px", minWidth: "80px", cursor: "pointer" },
      showContextMenu: true,
    },
    {
      Header: "Faculty Advisor/Supervisor",
      accessor: "faculty",
      style: { width: "160px", minWidth: "160px", cursor: "pointer" },
      showContextMenu: true,
    },
  ];

  const assignFacultyRowData = phdStudents?.map((student, index) => {
    return {
      select: <StudentRowListCheckBox student={student} />,
      sno: index + 1,
      name: student.name,
      roll: student.studentId,
      program: student.program,
      batch: student.batch,
      branch: student.branch,
      faculty: student.faculty,
    };
  });

  return (
    <>
      <AdminHeader />
      <AdminUpdatePopUp
        open={openAdminUpdatePopUp?.open}
        onClose={() => setOpenAdminUpdatePopUp({ open: false })}
        role={adminData?.role}
        manageFaculty={openAdminUpdatePopUp?.manageFaculty}
      />
      <EditAdminPopUp
        open={openEditAdminPopUp?.open}
        onClose={() => setOpenEditAdminPopUp({ open: false })}
        role={adminData?.role}
        manageFaculty={openEditAdminPopUp?.manageFaculty}
        adminData={specificAdminData}
      />
      {adminData?.role?.find((e) => {
        return e === "Super Admin";
      }) && (
        <div className="top-div">
          <div className="ManageAdmins">
            <div className="title cv">
              <div className="title-left-div">
                <h2>Manage Admins:</h2>
              </div>
              <div className="title-right-div">
                <div className="btn-options">
                  <button
                    className="option-btn selected-btn"
                    onClick={() =>
                      setOpenAdminUpdatePopUp({
                        open: true,
                        manageFaculty: false,
                      })
                    }
                  >
                    Add new admin
                  </button>
                </div>
              </div>
            </div>
            <div className="adminTableContainer">
              <Table
                columns={adminColumn}
                data={adminRowData}
                loading={false}
                className="manageAdminTable"
                bodyClassName="manageAdminBody"
              />
            </div>
          </div>
        </div>
      )}
      {(adminData?.role?.find((e) => {
        return e === "Super Admin";
      }) ||
        adminData?.role?.find((e) => {
          return e === "DOAA";
        })) && (
        <div className="bottom-div">
          <div className="ManageAdmins">
            <div className="title cv">
              <div className="title-left-div">
                <h2>Manage Faculties:</h2>
              </div>
              <div className="title-right-div">
                <div className="btn-options">
                  <button
                    className="option-btn selected-btn"
                    onClick={() =>
                      setOpenAdminUpdatePopUp({
                        open: true,
                        manageFaculty: true,
                      })
                    }
                  >
                    Add new faculty
                  </button>
                </div>
              </div>
            </div>
            <div className="adminTableContainer">
              <Table
                columns={facultyColumn}
                data={facultyRowData}
                loading={false}
                className="manageAdminTable"
                bodyClassName="manageAdminBody"
              />
            </div>
          </div>
        </div>
      )}
      <div className="mid-div">
        <div className="hostel-management-body manageAdminSearchBar">
          <div className="search-div">
            <div className="sub-title">
              <h2>Filter Students using following:</h2>
              <div className="search-item btn-div">
                <button
                  id="search1"
                  className="reset-btn"
                  onClick={() => resetFilter()}
                >
                  <span>Reset </span>
                  <i className="fa-solid fa-refresh"></i>
                </button>
              </div>
            </div>
            <div className="search-items">
              <div className="search-item">
                <h4 htmlFor="course">Program:</h4>
                <select
                  name="course"
                  id="course"
                  onChange={(e) =>
                    setSearch((e1) => {
                      return { ...e1, course: e.target.value };
                    })
                  }
                  value={search.course}
                >
                  <option disabled value="">
                    Select Program
                  </option>
                  <option value="M.TECH.">M.Tech</option>
                  <option value="Ph.D.">PhD</option>
                </select>
              </div>
              <div className="search-item">
                <h4 htmlFor="semester">Batch:</h4>
                <select
                  name="batch"
                  id="batch"
                  onChange={(e) =>
                    setSearch((e1) => {
                      return { ...e1, batch: e.target.value };
                    })
                  }
                  value={search.batch}
                >
                  <option disabled value="">
                    Select Batch
                  </option>
                  {search.course ? (
                    batchList[search.course]?.map((batch) => (
                      <option key={batch} value={batch}>
                        {batch}
                      </option>
                    ))
                  ) : (
                    <option value="null" disabled>
                      Select Course First
                    </option>
                  )}
                </select>
              </div>
              <div className="search-item">
                <h4 htmlFor="semester">Branch:</h4>
                <select
                  name="branch"
                  id="branch"
                  onChange={(e) =>
                    setSearch((e1) => {
                      return { ...e1, branch: e.target.value };
                    })
                  }
                  value={search.branch}
                >
                  <option disabled value="">
                    Select Branch
                  </option>
                  <option value="CSE">CSE</option>
                  <option value="ECE">ECE</option>
                  <option value="MAE">MAE</option>
                  <option value="MNC">MNC</option>
                </select>
              </div>
              <div className="search-item btn-div">
                <button
                  id="search"
                  className="new_btn primary"
                  onClick={filterStudents}
                >
                  <span>Filter</span>
                  <img src="/assets/search-icon.svg" alt="search-icon" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-div">
        <div className="student-assign">
          <div className="request-list student-table">
            <AdminContext.Provider value={{ assignFaculty, setAssignFaculty }}>
              <Table
                columns={assignFacultyColumn}
                data={assignFacultyRowData}
                loading={false}
                className="facultyStudentTable"
                bodyClassName="manageAdminBody"
              />
            </AdminContext.Provider>
          </div>
          <div className="allotment-btns">
            <h3>Assign Faculty Advisor/Supervisor</h3>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="formgroup">
                <label htmlFor="hostel" className="required">
                  Select Faculty
                </label>
                <select
                  id="hostel"
                  required
                  value={faculty}
                  onChange={(e) => {
                    setFaculty(e.target.value);
                  }}
                >
                  <option value="">Select Faculty</option>
                  {faculties?.map((e) => {
                    return (
                      <option value={e.name} key={e.id}>
                        {e.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <button
                className="allot-btn primary"
                type="submit"
                onClick={assignFacultyFunc}
              >
                Assign Selected
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageAdmins;
