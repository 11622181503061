import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AuthProvider } from "../auth/AuthContext";

const OtherActions = () => {
  const navigate = useNavigate();
  const { adminData } = AuthProvider();

  function CourseVerification() {
    navigate("/student/admin/course-verification");
  }

  function NewAdmissionProgress() {
    navigate("/student/admin/admission-progress");
  }

  function DocsAdmin() {
    navigate("/student/admin/documents-admin");
  }

  function StudentProgress() {
    navigate("/student/admin/studentprogress");
  }

  function MyCoursesPage() {
    navigate("/student/admin/my-courses");
  }

  function StudentBankDetails() {
    navigate("/student/admin/student-bank-details");
  }

  const [show, setShow] = React.useState({
    course: false,
    docs: false,
    result: false,
    admission: false,
    myCourses: true,
    studentBankDetails: false,
  });

  React.useEffect(() => {
    if (adminData?.role === "Overall Admin") {
      setShow((prev) => ({ ...prev, course: true, docs: true, result: true, admission: true, myCourses: true, studentBankDetails: true }));
    } else if (adminData?.role?.find((e) => { return e === "Finance Section" })) {
      setShow((prev) => ({ ...prev, course: false, docs: true, result: false, admission: true, studentBankDetails: true }));
    } else if (adminData?.role?.find((e) => { return e === "Academic Admin" }) || adminData?.role?.find((e) => { return e === "DOAA" }) || adminData?.role?.find((e) => { return e === "Academic Office" })) {
      setShow((prev) => ({ ...prev, course: true, docs: true, result: true, admission: true, studentBankDetails: true }));
    } else if (adminData?.role?.find((e) => { return e === "DOSA" })) {
      setShow((prev) => ({ ...prev, course: false, docs: true, result: false, admission: true, studentBankDetails: true }));
    } else if (adminData?.role?.find((e) => { return e === "HAB" })) {
      setShow((prev) => ({ ...prev, course: false, docs: true, result: false, admission: true, studentBankDetails: true }));
    } else if (adminData?.role?.find((e) => { return e === "Super Admin" })) {
      setShow((prev) => ({ ...prev, course: true, docs: true, result: true, admission: true, studentBankDetails: true }));
    } else if (adminData?.role?.find((e) => { return e === "Faculty Advisor" }) || adminData?.role?.find((e) => { return e === "Faculty" }) || adminData?.role?.find((e) => { return e === "HOD" })) {
      setShow((prev) => ({ ...prev, course: true, docs: true, result: true, admission: true }));
    } else if (adminData?.role?.find((e) => { return e === "Warden" })) {
      setShow((prev) => ({ ...prev, course: false, docs: true, result: false, admission: true }));
    } else if (adminData?.role?.find((e) => { return e === "Document Verifier" })) {
      setShow((prev) => ({ ...prev, course: false, docs: false, result: false, admission: true, myCourses: true, studentBankDetails: false }));
    } else if(adminData?.role?.find((e) => { return e === "Student's Application Verifier" })) {
      setShow((prev) => ({ ...prev, course: false, docs: true, result: false, admission: false, myCourses: false, studentBankDetails: false }));
    }
  }, [adminData])

  return (
    <>
      <div className="quick-action">
        <div className="title">
          <h2>Other Actions: </h2>
        </div>
        <div className="files">
          {show.course && <div className="file other-actions-file" onClick={CourseVerification}>
            <div className="filename oa-filename">
              <h1>Course Verification</h1>
            </div>
          </div>}
          {show.docs && <div className="file other-actions-file" onClick={DocsAdmin}>
            <div className="filename oa-filename">
              <h1>Form/Docs Sharing</h1>
            </div>
          </div>}
          {show.result && <div className="file other-actions-file" onClick={StudentProgress}>
            <div className="filename oa-filename">
              <h1>Student Progress<br />
                & Results</h1>
            </div>
          </div>}
          {show.myCourses && <div className="file other-actions-file" onClick={MyCoursesPage}>
            <div className="filename oa-filename">
              <h1>My Courses</h1>
            </div>
          </div>}
          {show.admission && <div
            className="file other-actions-file"
            onClick={NewAdmissionProgress}
          >
            <div className="filename oa-filename">
              <h1>New Admission<br />
                Data</h1>
            </div>
          </div>}
          {show.studentBankDetails && <div
            className="file other-actions-file"
            onClick={StudentBankDetails}
          >
            <div className="filename oa-filename">
              <h1>Student<br />
              Bank Details</h1>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
};

export default OtherActions;
