import React, { useEffect } from "react";
import "../css/Page/FeePayment.css";

import Header from "../components/Header";
import PaymentSummary from "../components/PaymentSummary";
import PaySection from "../components/PaySection";
import PaymentHistory from "../components/PaymentHistory";
import apis from "../apis";
import { AuthProvider } from "../auth/AuthContext";
import GatewayPopUp from "../components/GatewayPopUp";
import { CircularProgress } from "@mui/material";

const FeePayment = () => {
  const [due, setDue] = React.useState();
  const [openGateway, setOpenGateway] = React.useState({
    open: false,
    type: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [duePaymentHistory, setDuePaymentHistory] = React.useState([]);
  const [pastDue, setPastDue] = React.useState(0);
  const { userData } = AuthProvider();

  React.useEffect(() => {
    const fetchDue = async () => {
      setLoading(true);
      userData?.roll &&
        apis
          .specificStudent({ roll: userData?.roll, semester: userData?.semester })
          .then((data) => {
            setLoading(false);
            setDue(data[0].pastDue);
            setPastDue(Number(data[0].pastDue));
          })
          .catch((err) => {
            setLoading(false);
            alert(err.message);
          });
    };
    fetchDue();
  }, [userData]);


  useEffect(() => {
    if (duePaymentHistory.length > 0 && pastDue !== 0) {
      const newDue = duePaymentHistory.reduce((acc, curr) => {
        return Number(acc) + Number(curr.amount);
      }, 0);
      setDue(pastDue-newDue);
    }
  }, [duePaymentHistory, due, setDuePaymentHistory]);

  return (
    <>
    {loading && (
        <div className="loadingContainer2">
          <CircularProgress 
            size={60}
            thickness={5}
          />
        </div>
      )}
      <GatewayPopUp
        open={openGateway.open}
        types={openGateway.type}
        userData={userData}
        onClose={() => setOpenGateway(false)}
      />
      <Header />
      <div className="top-div">
        <PaymentSummary />
      </div>
      <div className="mid-div">
        <div className="paysection">
          <div className="paysection-div">
            <div className="past-due">
              <h2>Clear past due:</h2>
              <h2>
                <span>₹ {due}</span>
              </h2>
              {due <= 0 ? (
                <h2>
                  <span>All cleared 🎉</span>
                </h2>
              ) : (
                <button
                  className="primary"
                  disabled={due <= 0 ? false : true}
                  onClick={() =>
                    due > 0 &&
                    setOpenGateway({
                      open: true,
                      type: "due",
                    })
                  }
                >
                  Pay now
                </button>
              )}
            </div>
          </div>
        </div>
        {/* <div className="paysection-div"></div> */}
      </div>
      <div className="mid-div flex-column">
        <PaySection />
      </div>
      <div className="bottom-div">
        <PaymentHistory admin={false} setDuePaymentHistory={setDuePaymentHistory} />
      </div>
    </>
  );
};

export default FeePayment;
