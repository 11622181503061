import React, { useEffect } from "react";
import apis from "../apis";
import "../css/Component/PaymentDetailsTabular.css";

function formatDate(dateString) {
  const date = new Date(dateString);
  
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  const monthNames = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getUTCMonth()];

  // Function to add suffix to the day
  function getDayWithSuffix(day) {
    if (day > 3 && day < 21) return day + "th"; // General rule for teens
    switch (day % 10) {
      case 1: return day + "st";
      case 2: return day + "nd";
      case 3: return day + "rd";
      default: return day + "th";
    }
  }

  return `${getDayWithSuffix(day)} ${month} ${year}`;
}

const PaymentDetailsTabular = ({ personalData, feeStructure }) => {
  const [initialPayables, setInitialPayables] = React.useState(new Map());
  const [currentPaid, setCurrentPaid] = React.useState(new Map());
  const [paymentHistory, setPaymentHistory] = React.useState([]);

  useEffect(() => {
    if (personalData && feeStructure) {
      let instituteFees = 0;
      let messFee = 0;
      let hostelFee = 0;
      instituteFees =
        Number(feeStructure.tuitionfee) +
        Number(feeStructure.otherfee) +
        Number(feeStructure.convocationfee) +
        Number(feeStructure.cautionmoney) +
        Number(feeStructure.admissionfee);

        hostelFee = personalData?.opt
          ? personalData.hostel === "KBH"
            ? Number(feeStructure?.kbhfee)
            : personalData.hostel === "KGH"
            ? Number(feeStructure?.kghfee)
            : personalData.hostel === "H1"
            ? Number(feeStructure?.h1fee)
            : personalData.hostel === "GH"
            ? Number(feeStructure?.ghfee)
            : personalData.hostel === "NBH"
            ? Number(feeStructure?.nbhfee)
            : personalData.hostel === "H9"
            ? Number(feeStructure?.h9fee)
            : personalData.hostel === "H7"
            ? Number(feeStructure?.h7fee)
            : 0
          : 0;
    
        messFee = personalData?.opt ? Number(feeStructure?.messfee) : 0;

        setInitialPayables((prev) =>
          new Map(prev).set("Opening Balance", {
            particulars: "Opening Balance",
            creationDate: personalData?.promotionDate,
            payable: personalData?.pastDue,
            paid: "",
          })
        );

        setInitialPayables((prev) =>
          new Map(prev).set("Institute Fee", {
            particulars: "Institute Fee",
            creationDate: personalData?.promotionDate,
            payable: instituteFees,
            paid: "",
          })
        );

        setInitialPayables((prev) =>
          new Map(prev).set("Hostel Fee", {
            particulars: "Hostel Fee",
            creationDate: personalData?.promotionDate,
            payable: hostelFee,
            paid: "",
          })
        );

        setInitialPayables((prev) =>
          new Map(prev).set("Mess Fee", {
            particulars: "Mess Fee",
            creationDate: personalData?.promotionDate,
            payable: messFee,
            paid: "",
          })
        );
    }


    
  }, [personalData, feeStructure, setInitialPayables]);

  const fetchAdjustments = async () => {
    const datas = await apis.getSpecificAdjustments(
      personalData?.roll,
      personalData?.semester
    );
    const institutefineDatas = datas.filter(
      (data) =>
        (data.fineType === "fine" || data.fineType === "Fine") &&
        (data.type === "Institute" || data.type === "institute")
    );
    const hostelFineDatas = datas.filter(
      (data) =>
        (data.fineType === "fine" || data.fineType === "Fine") &&
        (data.type === "Hostel" || data.type === "hostel")
    );
    const messFineDatas = datas.filter(
      (data) =>
        (data.fineType === "fine" || data.fineType === "Fine") &&
        (data.type === "Mess" || data.type === "mess")
    );
    const instituteAdjustDatas = datas.filter(
      (data) =>
        (data.fineType === "Adjustment" || data.fineType === "adjustment") &&
        (data.type === "Institute" || data.type === "institute")
    );
    const hostelAdjustDatas = datas.filter(
      (data) =>
        (data.fineType === "Adjustment" || data.fineType === "adjustment") &&
        (data.type === "Hostel" || data.type === "hostel")
    );
    const messAdjustDatas = datas.filter(
      (data) =>
        (data.fineType === "Adjustment" || data.fineType === "adjustment") &&
        (data.type === "Mess" || data.type === "mess")
    );

    const totalInstituteFine = institutefineDatas.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );
    const totalHostelFine = hostelFineDatas.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );
    const totalMessFine = messFineDatas.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );
    const totalInstituteAdjust = instituteAdjustDatas.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );
    const totalHostelAdjust = hostelAdjustDatas.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );
    const totalMessAdjust = messAdjustDatas.reduce(
      (acc, curr) => acc + Number(curr.amount),
      0
    );

    if (
      Number(totalInstituteFine) === 0 &&
      Number(personalData?.instFine) > 0
    ) {
      setInitialPayables((prev) =>
        new Map(prev).set("Institute Fine", {
          particulars: "Institute Fine",
          creationDate: "",
          payable: personalData?.instFine,
          paid: "",
        })
      );
    } else if (Number(totalInstituteFine) === Number(personalData?.instFine)) {
      institutefineDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Institute Fine ${index + 1}`, {
            particulars: "Institute Fine",
            creationDate: data.createdAt,
            payable: data.amount,
            paid: "",
          })
        );
      });
    } else if (
      Number(totalInstituteFine) > 0 &&
      Number(totalInstituteFine) < Number(personalData?.instFine)
    ) {
      institutefineDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Institute Fine ${index + 1}`, {
            particulars: "Institute Fine",
            creationDate: data.createdAt,
            payable: data.amount,
            paid: "",
          })
        );
      });
      setInitialPayables((prev) =>
        new Map(prev).set("Institute Fine", {
          particulars: "Institute Fine",
          creationDate: "",
          payable: Number(personalData?.instFine) - Number(totalInstituteFine),
          paid: "",
        })
      );
    } else if (
      Number(totalInstituteFine) > 0 &&
      Number(totalInstituteFine) > Number(personalData?.instFine)
    ) {
      let currSum = 0;
      institutefineDatas.forEach((data, index) => {
        if (Number(currSum) < Number(personalData?.instFine)) {
          setInitialPayables((prev) =>
            new Map(prev).set(`Institute Fine ${index + 1}`, {
              particulars: "Institute Fine",
              creationDate: data.createdAt,
              payable: data.amount,
              paid: "",
            })
          );
          currSum += Number(data.amount);
        }
      });
    }

    if (Number(totalHostelFine) === 0 && Number(personalData?.hostelFine) > 0) {
      setInitialPayables((prev) =>
        new Map(prev).set("Hostel Fine", {
          particulars: "Hostel Fine",
          creationDate: "",
          payable: personalData?.hostelFine,
          paid: "",
        })
      );
    } else if (Number(totalHostelFine) === Number(personalData?.hostelFine)) {
      hostelFineDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Hostel Fine ${index + 1}`, {
            particulars: "Hostel Fine",
            creationDate: data.createdAt,
            payable: data.amount,
            paid: "",
          })
        );
      });
    } else if (
      Number(totalHostelFine) > 0 &&
      Number(totalHostelFine) < Number(personalData?.hostelFine)
    ) {
      hostelFineDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Hostel Fine ${index + 1}`, {
            particulars: "Hostel Fine",
            creationDate: data.createdAt,
            payable: data.amount,
            paid: "",
          })
        );
      });
      setInitialPayables((prev) =>
        new Map(prev).set("Hostel Fine", {
          particulars: "Hostel Fine",
          creationDate: "",
          payable: Number(personalData?.hostelFine) - Number(totalHostelFine),
          paid: "",
        })
      );
    } else if (
      Number(totalHostelFine) > 0 &&
      Number(totalHostelFine) > Number(personalData?.hostelFine)
    ) {
      let currSum = 0;
      hostelFineDatas.forEach((data, index) => {
        if (Number(currSum) < Number(personalData?.hostelFine)) {
          setInitialPayables((prev) =>
            new Map(prev).set(`Hostel Fine ${index + 1}`, {
              particulars: "Hostel Fine",
              creationDate: data.createdAt,
              payable: data.amount,
              paid: "",
            })
          );
          currSum += Number(data.amount);
        }
      });
    }

    if (Number(totalMessFine) === 0 && Number(personalData?.messFine) > 0) {
      setInitialPayables((prev) =>
        new Map(prev).set("Mess Fine", {
          particulars: "Mess Fine",
          creationDate: "",
          payable: personalData?.messFine,
          paid: "",
        })
      );
    } else if (Number(totalMessFine) === Number(personalData?.messFine)) {
      messFineDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Mess Fine ${index + 1}`, {
            particulars: "Mess Fine",
            creationDate: data.createdAt,
            payable: data.amount,
            paid: "",
          })
        );
      });
    } else if (
      Number(totalMessFine) > 0 &&
      Number(totalMessFine) < Number(personalData?.messFine)
    ) {
      messFineDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Mess Fine ${index + 1}`, {
            particulars: "Mess Fine",
            creationDate: data.createdAt,
            payable: data.amount,
            paid: "",
          })
        );
      });
      setInitialPayables((prev) =>
        new Map(prev).set("Mess Fine", {
          particulars: "Mess Fine",
          creationDate: "",
          payable: Number(personalData?.messFine) - Number(totalMessFine),
          paid: "",
        })
      );
    } else if (
      Number(totalMessFine) > 0 &&
      Number(totalMessFine) > Number(personalData?.messFine)
    ) {
      let currSum = 0;
      messFineDatas.forEach((data, index) => {
        if (Number(currSum) < Number(personalData?.messFine)) {
          setInitialPayables((prev) =>
            new Map(prev).set(`Mess Fine ${index + 1}`, {
              particulars: "Mess Fine",
              creationDate: data.createdAt,
              payable: data.amount,
              paid: "",
            })
          );
          currSum += Number(data.amount);
        }
      });
    }

    if (
      Number(totalInstituteAdjust) === 0 &&
      Number(personalData?.instAdjust) > 0
    ) {
      setInitialPayables((prev) =>
        new Map(prev).set("Institute Adjustment", {
          particulars: "Institute Adjustment",
          creationDate: "",
          payable: -Number(personalData?.instAdjust),
          paid: "",
        })
      );
    } else if (
      Number(totalInstituteAdjust) === Number(personalData?.instAdjust)
    ) {
      instituteAdjustDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Institute Adjustment ${index + 1}`, {
            particulars: "Institute Adjustment",
            creationDate: data.createdAt,
            payable: -Number(data.amount),
            paid: "",
          })
        );
      });
    } else if (
      Number(totalInstituteAdjust) > 0 &&
      Number(totalInstituteAdjust) < Number(personalData?.instAdjust)
    ) {
      instituteAdjustDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Institute Adjustment ${index + 1}`, {
            particulars: "Institute Adjustment",
            creationDate: data.createdAt,
            payable: -Number(data.amount),
            paid: "",
          })
        );
      });
      setInitialPayables((prev) =>
        new Map(prev).set("Institute Adjustment", {
          particulars: "Institute Adjustment",
          creationDate: "",
          payable:
            -(Number(personalData?.instAdjust) - Number(totalInstituteAdjust)),
          paid: "",
        })
      );
    } else if (
      Number(totalInstituteAdjust) > 0 &&
      Number(totalInstituteAdjust) > Number(personalData?.instAdjust)
    ) {
      let currSum = 0;
      instituteAdjustDatas.forEach((data, index) => {
        if (Number(currSum) < Number(personalData?.instAdjust)) {
          setInitialPayables((prev) =>
            new Map(prev).set(`Institute Adjustment ${index + 1}`, {
              particulars: "Institute Adjustment",
              creationDate: data.createdAt,
              payable: -Number(data.amount),
              paid: "",
            })
          );
          currSum += Number(data.amount);
        }
      });
    }

    if (
      Number(totalHostelAdjust) === 0 &&
      Number(personalData?.hostelAdjust) > 0
    ) {
      setInitialPayables((prev) =>
        new Map(prev).set("Hostel Adjustment", {
          particulars: "Hostel Adjustment",
          creationDate: "",
          payable: -Number(personalData?.hostelAdjust),
          paid: "",
        })
      );
    } else if (
      Number(totalHostelAdjust) === Number(personalData?.hostelAdjust)
    ) {
      hostelAdjustDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Hostel Adjustment ${index + 1}`, {
            particulars: "Hostel Adjustment",
            creationDate: data.createdAt,
            payable: -Number(data.amount),
            paid: "",
          })
        );
      });
    } else if (
      Number(totalHostelAdjust) > 0 &&
      Number(totalHostelAdjust) < Number(personalData?.hostelAdjust)
    ) {
      hostelAdjustDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Hostel Adjustment ${index + 1}`, {
            particulars: "Hostel Adjustment",
            creationDate: data.createdAt,
            payable: -Number(data.amount),
            paid: "",
          })
        );
      });
      setInitialPayables((prev) =>
        new Map(prev).set("Hostel Adjustment", {
          particulars: "Hostel Adjustment",
          creationDate: "",
          payable:
            -(Number(personalData?.hostelAdjust) - Number(totalHostelAdjust)),
          paid: "",
        })
      );
    } else if (
      Number(totalHostelAdjust) > 0 &&
      Number(totalHostelAdjust) > Number(personalData?.hostelAdjust)
    ) {
      let currSum = 0;
      hostelAdjustDatas.forEach((data, index) => {
        if (Number(currSum) < Number(personalData?.hostelAdjust)) {
          setInitialPayables((prev) =>
            new Map(prev).set(`Hostel Adjustment ${index + 1}`, {
              particulars: "Hostel Adjustment",
              creationDate: data.createdAt,
              payable: -Number(data.amount),
              paid: "",
            })
          );
          currSum += Number(data.amount);
        }
      });
    }

    if (Number(totalMessAdjust) === 0 && Number(personalData?.messAdjust) > 0) {
      setInitialPayables((prev) =>
        new Map(prev).set("Mess Adjustment", {
          particulars: "Mess Adjustment",
          creationDate: "",
          payable: -Number(personalData?.messAdjust),
          paid: "",
        })
      );
    } else if (Number(totalMessAdjust) === Number(personalData?.messAdjust)) {
      messAdjustDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Mess Adjustment ${index + 1}`, {
        particulars: `Mess Adjustment ${index + 1}`,
        creationDate: data.createdAt,
        payable: -Number(data.amount),
        paid: "",
          })
        );
      });
    } else if (
      Number(totalMessAdjust) > 0 &&
      Number(totalMessAdjust) < Number(personalData?.messAdjust)
    ) {
      messAdjustDatas.forEach((data, index) => {
        setInitialPayables((prev) =>
          new Map(prev).set(`Mess Adjustment ${index + 1}`, {
            particulars: "Mess Adjustment",
            creationDate: data.createdAt,
            payable: -Number(data.amount),
            paid: "",
          })
        );
      });
      setInitialPayables((prev) =>
        new Map(prev).set("Mess Adjustment", {
          particulars: "Mess Adjustment",
          creationDate: "",
          payable: -(Number(personalData?.messAdjust) - Number(totalMessAdjust)),
          paid: "",
        })
      );
    } else if (
      Number(totalMessAdjust) > 0 &&
      Number(totalMessAdjust) > Number(personalData?.messAdjust)
    ) {
      let currSum = 0;
      messAdjustDatas.forEach((data, index) => {
        if (Number(currSum) < Number(personalData?.messAdjust)) {
          setInitialPayables((prev) =>
            new Map(prev).set(`Mess Adjustment ${index + 1}`, {
              particulars: "Mess Adjustment",
              creationDate: data.createdAt,
              payable: -Number(data.amount),
              paid: "",
            })
          );
          currSum += Number(data.amount);
        }
      });
    }

    //now sort the datas of initialPayables whose particulars contains Fine and Adjustment in ascending order of creationDate
    // const fineDatas = initialPayables.filter((data) =>
    //   data.particulars.includes("Fine")
    // );
    // const adjustDatas = initialPayables.filter((data) =>
    //   data.particulars.includes("Adjustment")
    // );
    // fineDatas.sort((a, b) => {
    //   if (a.creationDate < b.creationDate) return -1;
    //   if (a.creationDate > b.creationDate) return 1;
    //   return 0;
    // });
    // adjustDatas.sort((a, b) => {
    //   if (a.creationDate < b.creationDate) return -1;
    //   if (a.creationDate > b.creationDate) return 1;
    //   return 0;
    // });

    // const newInitialPayables = initialPayables.filter(
    //   (data) =>
    //     !data.particulars.includes("Fine") &&
    //     !data.particulars.includes("Adjustment")
    // );
    // setInitialPayables([...newInitialPayables, ...fineDatas, ...adjustDatas]);
  };

  React.useEffect(() => {
    if (personalData && feeStructure) {
      fetchAdjustments();
    }
  }, [personalData, feeStructure]);

  React.useEffect(() => {
    if (personalData) {
      const fetchPaymentHistory = async () => {
        // also add alreadyPaid amount
        if(personalData?.alreadyPaid > 0){
          setCurrentPaid((prev) =>
            new Map(prev).set("Already Paid", {
              particulars: "JOSAA/CCMT Payment",
              creationDate: personalData?.createdAt,
              payable: "",
              paid: personalData?.alreadyPaid || 0,
            })
          );
        }
        await apis
          .getSpecificSuccessPayments(
            personalData?.roll,
            personalData?.semester
          )
          .then((data) => {
            setPaymentHistory(data);

            data.forEach((payment) => {
                setCurrentPaid((prev) =>
                new Map(prev).set(payment.createdAt, {
                  particulars: `${payment?.paidFor} (${payment?.type})`,
                  creationDate: payment.createdAt,
                  payable: "",
                  paid: Number(payment.amount).toFixed(2),
                })
                );
            });
          })
          .catch((err) => {
            alert(err);
          });
      };
      fetchPaymentHistory();
    }
  }, [personalData]);

  return (
    <div className="paymentTableCont7">
      <table className="paymentTable7">
        <thead className="paymentTableHead">
          <tr>
            <th>S.No.</th>
            <th>Creation Date</th>
            <th>Particulars</th>
            <th>Payable</th>
            <th>Paid</th>
          </tr>
        </thead>
        <tbody className="paymentTableBody">
          {Array.from(initialPayables.values()).map((data, index) => (
            <tr key={index} className="payableRows">
              <td style={{
                textAlign: "left",
              }}>{index+1}</td>
              <td>{formatDate(data.creationDate)}</td>
              <td>{data.particulars}</td>
              <td>{data.payable}</td>
              <td>{data.paid}</td>
            </tr>
          ))}
          {Array.from(currentPaid.values()).map((data, index) => (
            <tr key={index} className="paidRows">
              <td style={{
                textAlign: "right",
              }}>{index+1}</td>
              <td>{formatDate(data.creationDate)}</td>
              <td>{data.particulars}</td>
              <td>{data.payable}</td>
              <td style={{
                textAlign: "right",
              }}>{data.paid}</td>
            </tr>
          ))}
          {/* add two row one for total payable and another for total paid */}
          <tr style={{
            backgroundColor: "#f0f0f0",
          }}>
            <td colSpan="3" style={{
              textAlign: "right",
            }}>Total Payable</td>
            <td colSpan="2" style={{
              textAlign: "left",
            }}>₹ 
              {Array.from(initialPayables.values()).reduce(
                (acc, curr) => acc + Number(curr.payable),
                0
              )}
            </td>
          </tr>
          <tr style={{
            backgroundColor: "#f0f0f0",
          }}>
            <td colSpan="3" style={{
              textAlign: "right",
            }}>Total Paid</td>
            <td colSpan="2" style={{
              textAlign: "right",
            }}>₹ 
              {Array.from(currentPaid.values()).reduce(
                (acc, curr) => acc + Number(curr.paid),
                0
              )}
            </td>
          </tr>
          <tr style={{
            backgroundColor: "#f0f0f0",
          }}>
            <td colSpan="3" style={{
              textAlign: "right",
            }}>Total Due</td>
            <td colSpan="2" style={{
              textAlign: "center",
            }}>₹ 
              {Array.from(initialPayables.values()).reduce(
                (acc, curr) => acc + Number(curr.payable),
                0
              ) -
                Array.from(currentPaid.values()).reduce(
                  (acc, curr) => acc + Number(curr.paid),
                  0
                )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PaymentDetailsTabular;
