import React from "react";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

const BonafideHistoryContents = ({ history, setId, setAgain, setType, setTempType }) => {
  const navigate = useNavigate();

  const columns = [
    {
      field: "date",
      headerName: "Date",
      // valueFormatter: (params) => {
      //   return new Date(params?.value).toLocaleDateString();
      // },
      minWidth: 130,
      flex: 0.3,
    },
    {
      field: "roll_no",
      headerName: "Roll No.",
      minWidth: 130,
      flex: 0.3,
    },
    {
      field: "type",
      headerName: "Aplication Type",
      minWidth: 100,
      flex: 0.3,
    },

    {
      field: "purpose",
      headerName: "Purpose",
      sortable: false,
      minWidth: 270,
      flex: 0.5,
    },
    {
      field: "refId",
      headerName: "Ref-Id",
      sortable: false,
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "regenrate",
      headerName: "Regenrate",
      sortable: false,
      minWidth: 130,
      headerAlign: "center",
      align: "center",
      flex: 0.3,
      renderCell: (params) => {
        return (
            <div
              className="result-content-item-application re-generate"
              onClick={() =>
              {
                setId(params?.id);
                setAgain("a");
                setType(params?.applicationType);
                setTempType(params?.applicationType);
              }
              }
            >
              <a className="">Get Again</a>
            </div>
        );
      },
    },
  ];

  const rows = [];

  history?.map((item) => {
    rows.push({
      id: item?.id,
      date: item?.updatedAt?.split("T")[0],
      roll_no: item?.roll,
      type: item.applicationType === null ? "Bonafide" : item.applicationType,
      purpose: item?.purpose,
      refId: item?.refNo,
    });
  });

  return (
    // <div className="result-content">
    //   <div className="result-content-item">
    //     <h4>{history?.date}</h4>
    //   </div>
    //   <div className="result-content-item">
    //     <h4>{history?.roll}</h4>
    //   </div>
    //   <div className="result-content-item">
    //     <h4>{history?.purpose}</h4>
    //   </div>
    //   <div className="result-content-item">
    //     <h4>{history?.refNo}</h4>
    //   </div>
    //   <div className="result-content-item re-generate" onClick={() => navigate(`/student/admin/documents-bonafide/${history?.id}/a`)}>
    //     <a>Get Again</a>
    //   </div>
    // </div>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={10}
      disableSelectionOnClick={false}
      rowSelection={false}
      // autoPageSize
      // autoHeight
      className="productListTable"
    />
  );
};

export default BonafideHistoryContents;
