import React from 'react';
import '../css/Component/FilterComponent.css';

const FilterComponent = ({ filterAttributes, handleFilterChange, handleCheckboxChange, resetFilters }) => {
  const statusOptions = [
    {
      name: "Resolved",
      value: "Resolved"
    },
    {
      name: "In Review",
      value: "In Review"
    },
    {
      name: "Unresolved",
      value: "Unresolved"
    }
  ];
  const typeOptions = [
    {
      name: "Electricity/Electrical Appliances",
      value: "Electrical"
    },
    {
      name: "Internet",
      value: "Internet"
    },
    {
      name: "Civil",
      value: "Civil"
    },
    {
      name: "Others",
      value: "Others"
    }
  ];
  const locationOptions = [
    {
      name: "Academic Building",
      value: "Academic"
    },
    {
      name: "Administrative Building",
      value: "Administrative"
    },
    {
      name: "Central Library",
      value: "Library"
    },
    {
      name: "Lecture Hall",
      value: "Lecture"
    },
    {
      name: "Kautilya Boys Hostel",
      value: "KBH",
    },
    {
      name: "Kadambini Girls Hostel",
      value: "KGH"
    },
    {
      name: "Hostel 1",
      value: "H1"
    },
    {
      name: "Faculty Residence",
      value: "Faculty Residence"
    },
    {
      name: "Director Residence",
      value: "Director Residence"
    }
  ];
  const sortByOptions = [
    {
      name: "Most Recent",
      value: "Most Recent"
    },
    {
      name: "Oldest",
      value: "Oldest"
    },
    {
      name: "Upvotes",
      value: "Upvotes"
    }
  ];

  const [show1, setShow1] = React.useState(true);
  const [show2, setShow2] = React.useState(true);

  const toggleShow1 = () => {
    setShow1(!show1);
  };

  const toggleShow2 = () => {
    setShow2(!show2);
  };

  return (
    <div className="filter-container">
      <div className="filter-heading9"><span>Filters:</span> <button className='generalFilterResetBtn' onClick={()=>resetFilters()}><span>Reset </span><i className="fa-solid fa-refresh"></i></button></div>
      <div className="filter-row">
        <div className="filter-group">
          <label>Start Date:</label>
          <input
            type="date"
            name="startDate"
            value={filterAttributes.startDate}
            onChange={handleFilterChange}
          />
        </div>
        <div className="filter-group">
          <label>End Date:</label>
          <input
            type="date"
            name="endDate"
            value={filterAttributes.endDate}
            onChange={handleFilterChange}
          />
        </div>
      </div>

      <div className="filter-group">
        <label><span>Type:</span> <button onClick={()=>toggleShow1()} style={{width: "50px", fontSize: "12px"}}>{show1 ? "Hide" : "Show"}</button></label>
        <div className={`${!show1 ? "checkbox-group-hide": "checkbox-group"}`}>
          {typeOptions.map(type => (
            <label key={type} className="checkbox-label">
              <input
                type="checkbox"
                name="type"
                value={type.value}
                checked={filterAttributes.type.includes(type.value)}
                onChange={handleCheckboxChange}
              />
              {type.name}
            </label>
          ))}
        </div>
      </div>

      <div className="filter-group">
      <label><span>Location:</span> <button onClick={()=>toggleShow2()} style={{width: "50px", fontSize: "12px"}}>{show2 ? "Hide" : "Show"}</button></label>
      <div className={`${!show2 ? "checkbox-group-hide": "checkbox-group"}`}>
          {locationOptions.map(location => (
            <label key={location} className="checkbox-label">
              <input
                type="checkbox"
                name="location"
                value={location.value}
                checked={filterAttributes.location.includes(location.value)}
                onChange={handleCheckboxChange}
              />
              {location.name}
            </label>
          ))}
        </div>
      </div>

      <div className="filter-row">
        <div className="filter-group">
          <label>Status:</label>
          <select
            name="status"
            value={filterAttributes.status}
            onChange={handleFilterChange}
          >
            <option value="">Select Status</option>
            {statusOptions.map(status => (
              <option key={status} value={status.value}>{status.name}</option>
            ))}
          </select>
        </div>
        <div className="filter-group">
          <label>Sort By:</label>
          <select
            name="sortBy"
            value={filterAttributes.sortBy}
            onChange={handleFilterChange}
          >
            {sortByOptions.map(option => (
              <option key={option} value={option.value}>{option.name}</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;